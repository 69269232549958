
import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

export default class{

	constructor(){
        barba.hooks.afterOnce((data) => {
            this.init(data.next.container)
        })
        barba.hooks.after((data) => {
            this.init(data.next.container)
        });
    }
    init (container){
        gsap.registerPlugin(ScrollTrigger);
        
        const footer = container.querySelector("#l-footer")
        if(footer){
            ScrollTrigger.create({
                trigger: '#l-footer',
                start: '-10% top',
                end: 'bottom', 
                pin:".l-footer__bg--image",
                pinSpacing:false,
            })
        }
        
    }
}