
import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

export default class{

	constructor(){
        barba.hooks.afterOnce((data) => {
            // ★ここ
            this.animationEnd = false
            this.init(data.next.container)
        })
        barba.hooks.after((data) => {
            gsap.set("#p-index-hero__image img",{clipPath: "circle(100%)"})
            gsap.set(".p-index-hero__catch svg",{
                fill:"#fff",
                duration: 1.2,
            })
            gsap.set("#p-index-hero .-tomato",{scale:0})
            gsap.set(".p-index-hero__hull",{opacity:0})
            gsap.registerPlugin(ScrollTrigger);

            ScrollTrigger.create({
                trigger: '[data-hero-sticky]',
                start: 'top top',
                end: 'bottom top', 
                pin:"#p-index-hero__wrapper",
                pinSpacing:false
            })
            // ★ここ
            // this.animationEnd = false
            // this.init(data.next.container)
        });
    }
    init (container){
        gsap.registerPlugin(ScrollTrigger);
        
        const index = container.querySelector("#p-index")
        if(index){
            gsap.set("#page",{"height":"100vh","overflow":"hidden"})


            ScrollTrigger.create({
                trigger: '[data-hero-sticky]',
                start: 'top top',
                end: 'bottom top', 
                pin:"#p-index-hero__wrapper",
                pinSpacing:false
            })
            const tomatos = container.querySelectorAll("#p-index-hero .-tomato")
            gsap.set("#p-index-hero .-tomato",{scale:0})
            gsap.set(".p-index-hero__hull",{opacity:0})
            gsap.set(".p-index-hero__catch",{opacity:0})

            const tl = gsap.timeline({ delay: 1 })
            tl
            .to(
                "#p-index-hero__image img",
                {
                    clipPath: "circle(250px)",
                    duration: 1.2,
                    ease: "power3.out",
                    onStart:() => {
                        gsap.to("#p-index-hero .-tomato",
                        {
                            scale:1,
                            ease: "back.out",
                            delay: "random(0, .5)"
                        })
                        gsap.to(".p-index-hero__hull",
                        {
                            opacity:1,
                            duration: 1.2,
                            ease: "back.out",
                        })
                        gsap.to(".p-index-hero__catch",
                        {
                            opacity:1,
                            duration: 1.2,
                            ease: "back.out",
                        })
                    }
                }
            )
            .to(
                "#p-index-hero__image img",
               
                {
                    clipPath: "circle(100%)",
                    duration: 3,
                    ease: "power4.out",
                    delay: 1.5 ,
                    onStart:() => {
                        gsap.to(".p-index-hero__hull",
                        {
                            y:"-5px",
                            duration: 3,
                            ease: "power4.out",
                        })
                        ScrollTrigger.matchMedia({
                            "(min-width: 992px)": function() {
                                // if( !this.animationEnd ){
                                    gsap.to(
                                        ".p-index-hero__hull",
                                        {
                                            y:"-1000px",
                                            duration: 3,
                                            ease: "power4.out",
                                        }
                                    )
                                // }
                            },
                            "(max-width: 991px)": function() {
                        //         if( !this.animationEnd ){
                                    gsap.to(
                                        ".p-index-hero__hull",
                                        {
                                            y:"-120vh",
                                            duration: 3,
                                            ease: "power4.out",
                                        }
                                    )
                        //         }
                            },
                            
                        })
                        tomatos.forEach(t => {
                            const directionX = t.getAttribute("data-direction-x")
                            const directionY = t.getAttribute("data-direction-y")
                            gsap.to(
                                t,
                                {
                                    x:directionX,
                                    y:directionY,
                                    duration: 3,
                                    ease: "power3.out",
                                }
                            )
                        });

                        gsap.to(".p-index-hero__catch svg",
                        {
                            fill:"#fff",
                            duration: 1.2,
                        })
                        gsap.set("#page",{clearProps: "all"})

                    },
                    // onComplete(){
                    //     this.animationEnd = true
                    //     gsap.set(
                    //         ".p-index-hero__hull",
                    //         {
                    //             display:"none",
                    //         }
                    //     )
                    // }
                }
            )
            
        }
    }
}