import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


export default class{

	constructor(){

        barba.hooks.afterOnce((data) => {
            this.init(data.next.container)
        });
        barba.hooks.beforeLeave((data) => {
            window.removeEventListener("resize",this.resize)
        });
        barba.hooks.after((data) => {
            this.init(data.next.container)
        });

    }

    init(container){

        this.indexAbout = false
        this.logoAnime = false
        
        const indexAbout = document.querySelector("#p-index-feature")
        if(indexAbout&&(window.innerWidth > 991)){
            this.trigger = document.querySelector('#p-index-feature');
            this.elem = document.querySelectorAll('.p-index-feauture__img');
            if(this.elem) {
                this.elem.forEach((el, i) => {
                    gsap.to(el, {
                        y: '-10%',
                        scrollTrigger: {
                        trigger: this.trigger,
                        start: 'top 20%' ,
                        end: 'bottom 20%',  
                        scrub: 1,
                        //markers: true,
                        }
                    });
                })
            }
        }

        // const logoAnime = document.querySelector("#l-header__logo")
        // if(logoAnime&&(window.innerWidth > 991)){
        //     this.trigger = document.querySelector('');
        //     this.elem = document.querySelectorAll('#l-header__logo');
        //     if(this.elem) {
        //         this.elem.forEach((el, i) => {
        //             gsap.to(el, {
        //                 scrollTrigger: {
        //                 trigger: this.trigger,
        //                 start: 'center center',
        //                 end: 'bottom bottom-=200px',  
        //                 toggleClass: {targets: ".l-header__logo--link", className: "-logochange"},
        //                 // markers: true,
        //                 }
        //             });
        //         })
        //     }
        // }
        const logo = container.querySelector('#l-header__logo .l-header__logo--link')
        const triggers = container.querySelectorAll('[data-logo-inverse]')
        if(triggers.length > 0){
            triggers.forEach((el) => {
                ScrollTrigger.create({
                    trigger: el,
                    start: 'top-=60 top',
                    end: 'bottom top',
                    onEnter :() => {
                        logo.classList.add("-logochange")
                    },
                    onEnterBack:() => {
                        logo.classList.add("-logochange")
                    },
                    onLeave:() => {
                        logo.classList.remove("-logochange")
                    },
                    onLeaveBack:() => {
                        logo.classList.remove("-logochange")
                    },
                });
            })
        }

        this.resize()
        window.addEventListener("resize",this.resize.bind(this))

    }
    resize(){
        if(window.innerWidth > 991){
            if(this.indexAbout){
                this.indexAbout.enable()
            }
        }else{
            if(this.indexAbout){
                this.indexAbout.disable()
            }
        }
    }
}