import barba from '@barba/core';
import KeenSlider from 'keen-slider';


export default class{

	constructor(){
        barba.hooks.afterOnce((data) => {
            this.loopSlider()
        })
        barba.hooks.after((data) => {
            this.loopSlider()
        });
    }
    
    loopSlider(){
        const indexLoop = document.querySelector(".p-tomaful-story__slider");
        if(indexLoop) {
            const animation = { duration: 14000, easing: (t) => t }

            new KeenSlider('.keen-slider', {
                loop: true,
                //mode:'free',
                renderMode: "performance",
                drag: false,
                slides: {
                    origin: "center",
                    perView: 3,
                    spacing: 30,
                },
                breakpoints: {
                    "(max-width: 991px)": {
                        vertical: false,
                        slides: { perView: 3, spacing: 20 },
                    },
                    "(max-width: 767px)": {
                        vertical: false,
                        slides: { perView: 2.5, spacing: 20 },
                    },
                    "(max-width: 575px)": {
                        vertical: false,
                        slides: { perView: 2.2, spacing: 15 },
                    },
                },
                created(s) {
                    s.moveToIdx(2, true, animation)
                },
                updated(s) {
                    s.moveToIdx(s.track.details.abs + 2, true, animation)
                },
                animationEnded(s) {
                    s.moveToIdx(s.track.details.abs + 2, true, animation)
                },
            });
        }
    }
}